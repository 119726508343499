/**
 *
 *  Copyright 2019 Netflix, Inc.
 *
 *     Licensed under the Apache License, Version 2.0 (the "License");
 *     you may not use this file except in compliance with the License.
 *     You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 *     Unless required by applicable law or agreed to in writing, software
 *     distributed under the License is distributed on an "AS IS" BASIS,
 *     WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *     See the License for the specific language governing permissions and
 *     limitations under the License.
 *
 */

import { RecipeProvider, ProbeRecipe, RequesterOptions } from '../probe';

/**
 * Provides Probnik recipe based on provided provided during an initialization.
 */
export class LocalRecipeProvider implements RecipeProvider {
    private recipe: ProbeRecipe;

    /**
     * @param url URL of an API endpoint to call to get a recipe.
     */
    constructor(private json: object) {
        this.recipe = <ProbeRecipe>json;
    }

    /** Provides recipe to test. */
    public getRecipe(iteration: number, cb: (params: ProbeRecipe | null) => void): void {
        cb(this.recipe);
    }
}